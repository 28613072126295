html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  /* Set the background color for the entire page */
}

.mainContainer {
  width: 100%;
  min-height: 100vh;
  /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  background-color: inherit;
  /* Inherit the background color from the body */
}

.content {
  display: flex;
  flex: 1;
  /* Takes up the remaining space */
  flex-direction: row;
}

.verticalLine {
  width: 1px;
  background-color: #ccc;
  /* Or any color of your choice */
  margin: 0 20px;
  height: auto;
}

.centeredContent {
  padding: 20px;
  margin-top: 20px;
  /* Adds margin below the Header */
  display: flex;
  flex-direction: row;
  /* Layout child elements horizontally */
  align-items: center;
}

.dateLabel {
  margin: 10px;
}

.toggleContainer {
  margin-left: 20px;
  /* Add some space between the date inputs and the toggle switch */
  width: 100px;
  height: 40px;
  background-color: #e0e0e0;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}

.toggleSwitch {
  width: 50%;
  height: 100%;
  background-color: #ffcc80;
  /* Light orange color */
  border-radius: 20px;
  position: absolute;
  transition: transform 0.3s ease;
}

.toggleText {
  flex: 1;
  text-align: center;
  z-index: 1;
}

.toggleText.easy {
  color: #000;
}

.toggleText.hard {
  color: #000;
}

.toggleText.hidden {
  visibility: hidden;
}

.tablesContainer {
  width: 100%;
  max-width: 1600px;
  /* Twice the size of the previous max-width */
  padding: 20px;
}

.loadingAnimation {
  border: 6px solid #f3f3f3;
  /* Light grey */
  border-top: 6px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}