.tableContainer {
    margin-top: 20px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    background-color: #fff;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Ensures fixed table layout */
  }
  
  .table th,
  .table td {
    border: 1px solid #ddd;
    padding: 16px; /* Larger padding for bigger cells */
    width: 50%; /* Ensures equal width for each cell */
    word-wrap: break-word; /* Ensures content wraps within the cell */
  }
  
  .table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .table td {
    text-align: left;
  }
  