.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 9%;
  margin-left: 33%;
  width: 30%;
  padding: 2%;
}

.orangeAi {
  font-size: 28px;
  font-weight: bold;
}

.loginForm {
  box-shadow: 0 20px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px; /* Optional: if you want rounded corners */
  padding: 8% 4%;
  display: flex;
  flex-direction: column;
  margin-top: 8%;
  background-color: white;
}

.loginText {
  display: flex;
  justify-content: center;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  margin: 2% 4%;
}

.formInputHeading {
  margin-bottom: 2%;
  color: #6c7484;
}

.formInput {
  height: 30px;
  padding: 1%;
}

.submitButton {
  margin: 4% 4%;
  height: 40px;
  font-size: 16px;
  font-weight: bolder;
  background-color: orange;
}

.submitButton:hover {
  transform: translateY(-1px); /* Slight raise effect on hover */
  cursor: pointer;
}

.footer{
  margin: 2% 8%;
}
