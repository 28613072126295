.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid black;
    width: 100%;
}

.signOutButton {
    height: 25px;
}

.signOutButton:hover {
    cursor: pointer;
}

@media (max-width: 768px) {
    .welcomeHeading {
        font-size: 12px;
    }
}