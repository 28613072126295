html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #f5f5ee; /* Set the background color for the entire page */
  }

.mainContainer {
    display: flex;
    height: 100vh;
    /* background: linear-gradient(to bottom, white, #f5f5ee); */
    /* background: linear-gradient(to bottom, #f5f5ee,); */
    background-color: #f5f5ee;
}

.leftSide {
    width: 12%;
    background-color: antiquewhite;
    padding: 1%;
}

.rightSide {
    padding: 1%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.divider {
    cursor: col-resize;
    background-color: gray; /* Or any color you prefer for the divider */
    width: 5px; /* Adjust the width of the divider as needed */
}